<template>
  <transition name="fade">
    <div class="cookie-panel-wrapper" v-show="notcookie && data_loaded" v-cloak>
      <div class="cookie-panel" v-if="data_loaded">
        <!-- <pre>
          {{cookie_api_data}}
        </pre> -->

        <h2>
          {{ cookie_api_data.cookie_header_text }}
          <!--Our use of cookies-->
        </h2>
        <p v-html="cookie_api_data.add_cookie_html"></p>
        <br />

        <!-- We use cookies to enable essential services and functionality on our
          site, to enhance your browsing experience, to provide a better service
          through personalized content, and to collect data on how visitors
          interact with our site. To accept the use of all cookies, click
          "Accept all". To reject the use of all cookies, click “Reject all”.
          For more information about our use of cookies and to opt-out of
          cookies at any time, please refer to our website <a target="_blank" href="https://google.com">Privacy Notice.</a> -->

        <div class="cookie-options-buttons">
          <button @click="accept_all()">
            {{ cookie_api_data.cookie_accept_button_text }}
            <!-- Accept all cookies -->
          </button>
          <button @click="decline_all()">
            {{ cookie_api_data.cookie_reject_button_text }}
            <!-- Reject all cookies -->
          </button>
        </div>

        <hr />

        <h3>{{ cookie_api_data.cookie_required_label_text }}</h3>
        <div v-html="cookie_api_data.add_required_html"></div>

        <hr />

        <div class="cookie-section-header">
          <h3>{{ cookie_api_data.cookie_optional_label_text }}</h3>
          <label class="switch">
            <input
              type="checkbox"
              v-model="optional_checked"
              @change="optional_change()"
            />
            <span class="slider">
              <span class="slider-on">{{ getrans("On") }}</span>
              <span class="slider-off">{{ getrans("Off") }}</span>
            </span>
          </label>
        </div>
        <div v-html="cookie_api_data.add_optional_html"></div>

        <hr />

        <button @click="accept">{{ getrans("Save and close") }}</button>
      </div>
    </div>
  </transition>
</template>
<script>
const axios = require("axios");
const { EncryptStorage } = require("encrypt-storage");
const crypto = require("crypto");
const encryptStorage = new EncryptStorage(process.env.VUE_APP_CHL, {
  storageType: "sessionStorage",
});
import md5 from "js-md5";
export default {
  name: "Cookies",
  props: {
    brandSlug: undefined,
    translations: undefined,
  },
  components: {},
  data() {
    return {
      notcookie: false,
      lang: "en",
      cookie_api_data: {},
      optional_checked: false,
      data_loaded: false,
    };
  },
  methods: {
    getrans(string) {
      var s = md5(string);
      var ss = this.brandSlug + "-" + this.lang.toUpperCase() + "-" + s;
      console.log(ss);
      return this.translations[ss] || string;
    },
    accept_all() {
      this.setCookie("cookie_choice_made", "accept", 365);
      this.optional_checked = true;
      this.accept();
    },

    decline_all() {
      this.setCookie("cookie_choice_made", "decline", 365);
      this.optional_checked = false;
      this.accept();
    },

    optional_change() {
      console.log("optional_change", this.optional_checked);

      if (this.optional_checked === true) {
        this.setCookie("cookie_choice_made", "accept", 365);
      } else {
        this.setCookie("cookie_choice_made", "decline", 365);
      }
    },
    accept() {
      this.notcookie = false;

      if (this.optional_checked === true) {
        this.setCookie("cookie_choice_made", "accept", 365);

        if (
          location.host == "localhost:7090" ||
          location.host == "airv6devcombined.rezcommdev.com"
        ) {
          window.consentGranted("GTM-PD2QS3H7");
        }

        if (
          location.host == "localhost:7088" ||
          location.host == "nwiv6devcombined.rezcommdev.com"
        ) {
          window.consentGranted("GTM-W92QPJH");
        }

        if (
          location.host == "localhost:7081" ||
          location.host == "rduv6devcombined.rezcommdev.com"
        ) {
          //window.consentGranted("GTM-WCRG7GM");

          let scriptEle = document.createElement("script");
          scriptEle.setAttribute("src", "https://pipeline.rdu.com/index.js");
          // scriptEle.setAttribute(
          //   "data-host",
          //   "https://api.us-east.tinybird.co"
          // );
          scriptEle.setAttribute(
            "data-token",
            "p.eyJ1IjogImJmYTQyZmI1LWY3MWItNDVlMi1hYjY3LTFiZTViNjQwMjM3YyIsICJpZCI6ICI4NDA2ZWU4MC1lNTU0LTQzYmUtODFkYy1iZDkyZWM4MWNjOGQifQ.ROvEB0OIvKv4xs1T9JSuy2EfwKZEpxTw_f_FbJfnrjE"
          );
          document.body.appendChild(scriptEle);
          scriptEle.addEventListener("load", () => {
            console.log("Tinybird script loaded");
          });
        }
        /*         let scriptEle = document.createElement("script");
        scriptEle.setAttribute("src", "https://unpkg.com/@tinybirdco/flock.js");
        scriptEle.setAttribute(
          "data-token",
          "p.eyJ1IjogIjExNjc3NDUwLWJkYjAtNGEyNi05NTZlLTlmNGE0OWNlNjAxZiIsICJpZCI6ICIyNjk2YTM0Zi0yNjJlLTRmOTgtYWM2Zi1hNTAwMTVmMTQ5ODQifQ.qAkna78f0qF7lAVtIsZ3k-DrFZh6F-UtkrdBldgLzd0"
        );
        document.body.appendChild(scriptEle);
        scriptEle.addEventListener("load", () => {
          console.log("tn script loaded");
          console.log('Tinybird',window.Tinybird);
          console.log('trackEvent',{ campaign_id: this.getCookie("campaign_id"), campaign_name: this.getCookie("campaign_name"),
        partner_id: this.getCookie("partner_id"), partner_name: this.getCookie("partner_name"),session_id:this.getCookie('session-id') });
        window.Tinybird.trackEvent('pageload', { campaign_id: this.getCookie("campaign_id"), campaign_name: this.getCookie("campaign_name"),
        partner_id: this.getCookie("partner_id"), partner_name: this.getCookie("partner_name"),session_id:this.getCookie('session-id') });          
        });

        scriptEle.addEventListener("error", (ev) => {
          console.log("Error on loading tinybird script", ev);
        }); */
      } else {
        this.setCookie("cookie_choice_made", "decline", 365);
        window.consentDenied();
      }
      //fire off the consent given event
    },
    gensessionid() {
      if (encryptStorage.getItem("search_analytics_id") === undefined) {
        let search_analytics_id = crypto.randomBytes(16).toString("base64");
        encryptStorage.setItem("search_analytics_id", search_analytics_id);
        return search_analytics_id;
      } else {
        return encryptStorage.getItem("search_analytics_id");
      }
    },
    decline() {
      this.notcookie = false;
      this.setCookie("cookie_choice_made", "decline", 365);
      // fire off the consent denied event
    },
    setCookie(name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    getSessionData(name) {
      return JSON.parse(sessionStorage.getItem(name));
    },
    get_cookie_data() {
      if (this.brandSlug) {
        // let api_url =
        //   "https://devhub.rezcomm.com/hubapmydbauth/brandapi/livecookie.php?brandslug=" +
        //   this.brandSlug;
        let api_url =
          "https://devhub.rezcomm.com/hubapmydbauth/brandapi/devcookie.php?brandslug=" +
          this.brandSlug;
        axios
          .post(api_url)
          .then((response) => response)
          .then((data) => {
            // this.cookie_api_data = data.data;
            let acquire_data = {};
            for (var n = 0; n < data.data.length; n++) {
              let cdata = data.data[n];
              let lng = cdata["lang"];
              console.log("lng", lng + "===" + this.lang);
              if (lng == this.lang) {
                acquire_data = cdata;
              }
            }
            setTimeout(() => {
              this.cookie_api_data = acquire_data;
              this.data_loaded = true;
            }, 0);
          });
      }
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    console.log("urlParams", urlParams);

    if (
      this.brandSlug === "FMM" ||
      this.brandSlug === "SZG" ||
      this.brandSlug === "BRE" ||
      this.brandSlug === "PB1"
    ) {
      this.lang = "de";
    }
    if (urlParams.has("lang")) {
      this.lang = urlParams.get("lang").toLowerCase();
    }

    if (this.brandSlug !== "none") {
      let cookieState = this.getCookie("cookie_choice_made");
      if (cookieState) {
        if (cookieState === "accept") {
          this.optional_checked = true;
          this.accept();
        } else {
          this.optional_checked = false;
          this.decline();
        }
      } else {
        this.notcookie = true;
      }

      window.addEventListener(
        "toggle_cookie_settings",
        () => {
          this.notcookie = !this.notcookie;
        },
        false
      );

      this.get_cookie_data();
    } else {
      this.notcookie = false;
    }
  },
  updated() {
    const urlParams = new URLSearchParams(window.location.search);
    if (
      this.brandSlug === "FMM" ||
      this.brandSlug === "SZG" ||
      this.brandSlug === "BRE" ||
      this.brandSlug === "PB1"
    ) {
      this.lang = "de";
    }
    if (urlParams.has("lang")) {
      this.lang = urlParams.get("lang").toLowerCase();
    }
    if (this.brandSlug !== "none") {
      this.get_cookie_data();
    } else {
      this.notcookie = false;
    }
  },
};
</script>

<style lang="scss">
.cookie-panel {
  a {
    color: var(--main-cookie-text-color);
    text-decoration: underline !important;
    font-weight: bold;
  }
}
</style>

<style scoped lang="scss">
[v-cloak] {
  display: none;
}

@import "@/assets/sass/variables.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.cookie-panel-wrapper {
  background: #00000040;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 9999999999999;
  //pointer-events: none;
}

.cookie-panel::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  background-color: var(--main-cookie-text-color);
}

.cookie-panel {
  -ms-overflow-style: 9px; /* IE and Edge */
  scrollbar-width: 9px; /* Firefox */
  --main-cookie-panel-color: #0666ae;
  --main-cookie-text-color: #fff;
  pointer-events: all;
  background: var(--main-cookie-panel-color);
  color: var(--main-cookie-text-color);
  position: fixed;
  // bottom: 0px;
  left: 0%;
  top: 0%;
  // transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 60ch;
  max-height: calc(100vh);
  overflow-y: auto;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    top: 20%;
    height: 80%;
  }

  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-bottom: 10rem;
  @media screen and (max-width: 576px) {
    padding: 1rem;
    padding-bottom: 10rem;
  }
  font-family: $font-family-medium;
  text-align: left;
  z-index: 9999999999999;
  box-shadow: 0 0px 20px 0 rgb(0 0 0 / 20%);
  a {
    color: var(--main-cookie-text-color);
    text-decoration: underline !important;
    font-weight: bold;
  }

  button {
    background: var(--main-cookie-text-color);
    border: 0px solid var(--main-cookie-text-color);
    color: var(--main-cookie-panel-color);
    padding: 0.5rem 2rem;
    border-radius: 0.25rem;
    font-weight: bold;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .cookie-options-buttons {
    display: flex;
    gap: 1rem;
    padding: 0rem 0 2rem;
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
}

.switch {
  --switch-width: 4px;
  position: relative;
  display: inline-block;
  width: calc(var(--switch-width) + 60px);
  min-width: calc(var(--switch-width) + 60px);
  height: 28px;
  margin: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-on,
.slider-off {
  position: absolute;
  user-select: none;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  color: #fff;
}

.slider-on {
  opacity: 0;
  left: 10px;
}

.slider-off {
  opacity: 1;
  right: 10px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #174569;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 3rem;
  display: flex;
  align-items: center;

  .slider-on {
    opacity: 0;
  }

  .slider-off {
    opacity: 1;
  }
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 3px;
  bottom: 3px;
  background-color: var(--main-cookie-panel-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 100%;
  z-index: 333;
}

input:checked + .slider {
  background-color: #174569;

  .slider-on {
    opacity: 1;
  }

  .slider-off {
    opacity: 0;
  }
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(calc(var(--switch-width) + 32px));
  -ms-transform: translateX(calc(var(--switch-width) + 32px));
  transform: translateX(calc(var(--switch-width) + 32px));
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

hr {
  border: 1px solid var(--main-cookie-text-color);
  margin: 1rem 0;
  opacity: 0.25;
}

.cookie-section-header {
  display: flex;
  justify-content: space-between;
}
</style>
